<template>
  <div>
    <el-dialog
      v-if="menuCategoryEditData"
      :title="
        'Дотоод ангиллын нэр : ' + menuCategoryEditData.menu_category_mon + ''
      "
      :visible.sync="sendEdit"
      @close="closeDialog()"
      width="40%"
      top="100px"
      class="process-dialog"
    >
      <div class="panel">
        <el-form
          :model="menuCategoryEditData"
          ref="menuCategoryEditData"
          label-width="220px"
          class="demo-ruleForm"
        >
          <el-form-item label="Монгол нэр" prop="menu_category_mon">
            <el-input
              style="width: 250px"
              size="mini"
              v-model="menuCategoryEditData.menu_category_mon"
              placeholder="Монгол нэр оруулна уу"
            ></el-input>
          </el-form-item>
          <el-form-item label="Англи нэр" prop="menu_category_eng">
            <el-input
              style="width: 250px"
              size="mini"
              v-model="menuCategoryEditData.menu_category_eng"
              placeholder="Англи нэр оруулна уу"
            ></el-input>
          </el-form-item>
          <el-form-item label="/сервес/" prop="service">
            <el-select
              placeholder="Үйлчилгээ"
              v-model="menuCategoryEditData.service"
              size="mini"
              clearable
            >
              <el-option
                v-for="(service, index) in servicesList"
                :label="service.name_mon"
                :value="service.name_eng"
                :key="index"
              ></el-option>
            </el-select>
          </el-form-item>
          <el-button type="primary" @click="save" size="mini"
            >Хадгалах</el-button
          >
        </el-form>
      </div>
    </el-dialog>
  </div>
</template>

<script>
import service from "@/helpers/services.js";
export default {
  props: {
    menuCategoryEditData: Object,
    sendEdit: Boolean,
    closeProgress: Function,
    servicesList: Array
  },
  methods: {
    save() {
      const payload = {};
      payload.id = this.menuCategoryEditData.id;
      payload.name_mon = this.menuCategoryEditData.menu_category_mon;
      payload.name_eng = this.menuCategoryEditData.menu_category_eng;
      payload.service = this.menuCategoryEditData.service;
      service.updateMenuCategory(payload).then(response => {
        if (response.status === "success") {
          this.$notify({
            title: "Амжилттай",
            message: "Дотоод ангилал засагдлаа",
            type: "success"
          });
          this.closeDialog();
        } else if (response.status === "unsuccess") {
          this.$notify({
            title: "Амжилтгүй",
            message:
              "Дотоод ангилал засахад алдаа гарлаа " +
              " " +
              response.error.message,
            type: "warning"
          });
        }
      });
    },
    closeDialog() {
      this.closeProgress();
    }
  },
  data() {
    return {
      loadUpload: false,
      imageNull: "none"
      // rules: {
      //   name_mon: [
      //     {
      //       required: true,
      //       message: "Та зарлал нэрээ оруулна уу",
      //       trigger: "blur"
      //     },
      //     {
      //       min: 3,
      //       message: "Хамгийн багадаа 3 үсэг оруулна уу",
      //       trigger: "blur"
      //     }
      //   ]
      // }
    };
  }
};
</script>
<style></style>
