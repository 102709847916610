<template>
  <div>
    <div class="page-header panel">
      <h3 class="title">
        Дотоод ангилал
      </h3>
      <div class="action-section">
        <router-link to="/add-menu-category">
          <el-button type="success" size="mini" icon="el-icon-plus"
            >Нэмэх</el-button
          >
        </router-link>
      </div>
    </div>
    <el-row :gutter="0">
      <el-col :span="24" :offset="0">
        <div class="panel payments-container">
          <div align="right">
            <el-select
              placeholder="Үйлчилгээ"
              v-model="service"
              size="mini"
              @change="searchService"
              clearable
            >
              <el-option
                v-for="(service, index) in servicesList"
                :label="service.name_mon"
                :value="service.name_eng"
                :key="index"
              ></el-option>
            </el-select>
            <el-input
              style="width: 200px;"
              prefix-icon="el-icon-search"
              v-model="search"
              @input="searchCategory"
              size="mini"
              placeholder="Хайх суртчилгааны нэр"
            />
          </div>
          <el-table :data="menuCategoryList">
            <el-table-column prop="menu_category_mon" label="Монгол нэр">
            </el-table-column>
            <el-table-column prop="menu_category_eng" label="Англи нэр">
            </el-table-column>
            <el-table-column prop="service" label="Төрөл"></el-table-column>
            <el-table-column
              label="Үйлдэл"
              width="150px"
              v-if="!mArrayIncludes(role, ['client_care', 'other'])"
            >
              <template slot-scope="scope">
                <el-button
                  type="primary"
                  size="mini"
                  icon="el-icon-edit"
                  @click="showEdit(scope.row)"
                  round
                ></el-button>
                <el-button
                  type="danger"
                  size="mini"
                  icon="el-icon-delete"
                  @click="deleteData(scope.row)"
                  round
                ></el-button>
              </template>
            </el-table-column>
          </el-table>
          <el-pagination
            background
            layout="prev, pager, next"
            :total="pagetotal"
            :page-size="size"
            @current-change="setPage"
          >
          </el-pagination>
        </div>
      </el-col>
    </el-row>
    <edit-menu-category
      :menuCategoryEditData="menuCategoryEditData"
      :sendEdit="sendEdit"
      :closeProgress="closeProgress"
      :servicesList="this.servicesList"
    ></edit-menu-category>
  </div>
</template>

<script>
import service from "@/helpers/services.js";
import { arrayIncludes } from "../helpers/helper";
import { getGroups } from "../utils/auth";
import EditMenuCategory from "@/components/EditMenuCategory";
export default {
  name: "menuCategory",
  mounted() {
    this.getMenuCategoriesList();
    this.getServices();
    getGroups().then(data => {
      this.role = data;
    });
  },
  components: { EditMenuCategory },
  data() {
    return {
      menuCategoryList: [],
      pagetotal: 0,
      search: "",
      size: 10,
      from: 0,
      role: [],
      service: "",
      servicesList: [],
      menuCategoryEditData: null,
      sendEdit: false
    };
  },
  methods: {
    getMenuCategoriesList() {
      this.openFullScreenLoader(true);
      service
        .getMenuCategories(this.service, this.search, "default")
        .then(res => {
          this.menuCategoryList = res.data.data;
          this.pagetotal = res.data.total;
          this.openFullScreenLoader(false);
        });
    },
    mArrayIncludes(base_array, searchArray) {
      return arrayIncludes(base_array, searchArray);
    },
    setPage(val) {
      this.from = val;
      this.getMenuCategoriesList();
    },
    searchCategory() {
      clearTimeout(this.timer);
      this.timer = setTimeout(() => {
        this.from = 1;
        this.getMenuCategoriesList();
      }, 1000);
    },
    openFullScreenLoader(status) {
      if (status) {
        this.loading = this.$loading({
          lock: true,
          text: "татаж авч байна...",
          spinner: "el-icon-loading",
          background: "rgba(0, 0, 0, 0.7)"
        });
      } else {
        this.loading.close();
      }
    },
    getServices() {
      service.getServices().then(servicesResponse => {
        let b = servicesResponse.data.data.map(el => {
          el.name_eng = el.name_eng.toLowerCase();
          el.name_mon = el.name_mon.toLowerCase();
          return el;
        });
        this.servicesList = b;
      });
    },
    searchService() {
      this.from = 1;
      this.getMenuCategoriesList();
    },
    deleteData(data) {
      const payroll = {};
      payroll.id = data.id;
      this.$confirm("Устгахдаа итгэлтэй байна уу?", data.name_mon, {
        confirmButtonText: "Тийм",
        cancelButtonText: "Үгүй",
        type: "warning"
      })
        .then(() => {
          service.deleteMenuCategory(payroll).then(response => {
            if (response.status === "success") {
              this.$notify({
                title: "Амжилттай устгагдлаа",
                message: response.message,
                type: "success"
              });
              this.getMenuCategoriesList();
            } else {
              this.$notify({
                title: "Амжилтгүй",
                message:
                  "Дотоод ангилал устгахад алдаа гарлаа " +
                  " " +
                  response.error.message,
                type: "warning"
              });
            }
          });
        })
        .catch(() => {
          this.$message({
            type: "info",
            message: "Устгах үйлдэл цуцлагдсан"
          });
        });
    },
    closeProgress() {
      this.menuCategoryEditData = null;
      this.sendEdit = false;
      this.getMenuCategoriesList();
    },
    showEdit(data) {
      this.menuCategoryEditData = data;
      this.sendEdit = true;
    }
  }
};
</script>
